import HTTP from './../../Api/auth'
const state = {
  programmesV2: [],
  singleProgrammesV2:'',
  progerrorsV2:'',
  msgFailProgramV2:"",
  programmeV2:[],
  progrubriquesV2: [],
  assignProgrammeV2: [],
  updateProgrammeMsgV2:'',
  deleteProgrammeMsgV2:'',
  programmeRecapV2:[],
  failProgrammeRecapV2:"",
}

const getters = {
  programmesV2: state => state.programmesV2,
  singleProgrammesV2: state => state.singleProgrammesV2,
  progerrorsV2: state => state.progerrorsV2,
  programmeV2: state => state.programmeV2,
  msgFailProgramV2: state => state.msgFailProgramV2,
  progrubriquesV2: state => state.progrubriquesV2,
  assignProgrammeV2: state => state.assignProgrammeV2,
  updateProgrammeMsgV2: state => state.updateProgrammeMsgV2,
  deleteProgrammeMsgV2: state => state.deleteProgrammeMsgV2,
  programmeRecapV2: state => state.programmeRecapV2,
  failProgrammeRecapV2: state => state.failProgrammeRecapV2,
}
const actions = {
  ProgrammesV2({commit},data){
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"programmes"+query).then((response) => {
      commit("setProgrammesV2", response.data)
    }).catch((error) => {
      commit("setErorsV2", error.response.data.code_message)
    })
  },
  ListProgrammesV2({commit},data){
    let query=""
    var count=1
    var used=0
    if (data!=null) {
      query="?filtres=["  
      data.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
              +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                query+=","
              }
              query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
              +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
              +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              query+=","
              console.log(count)
            }
            query+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      query+="]"
    }
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"programmes-sans-pagination"+query).then((response) => {
      commit("setProgrammesV2", response.data)
    }).catch((error) => {
      commit("setErorsV2", error.response.data.code_message)
    })
  },
  pageProgrammesV2({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+data).then((response) => {
      commit("setProgrammesV2", response.data)
    }).catch((error) => {
      commit("setErorsV2", error.response.data.code_message)
    })
  },
  saveProgrammesV2({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL_V2+"programmes", data).then((response) => {
      commit("singleProgramesV2", response.data )
      commit("setMsgFailProgramV2", '')
    }).catch((error) => {
      commit("singleProgramesV2",'')
      commit("setMsgFailProgramV2", error.response.data.code_message)
    })
  },
  getProgrammeV2({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"programmes/"+data).then((response) => {
      commit("setProgrammeV2", response.data)
    }).catch((error) => {
      commit("setErorsV2", error.response.data.code_message)
    })
  },
  getRubriquesV2({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"rubriques").then((response) => {
      commit("setRubriquesV2", response.data)
    }).catch((error) => {
      commit("setErorsV2", error.response.data.code_message)
    })
  },
  updateProgrammesV2({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL_V2+"programmes/"+data.id,data).then(() => {
      commit("setUpdateProgrammeV2", 'Le programme a bien été modifié')
      commit("setErorsV2", '')
    }).catch((error) => {
      commit("setUpdateProgrammeV2",'')
      commit("setErorsV2", error.response.data.erreurs)
    })
  },
  deleteProgrammesV2({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL_V2+"programmes/"+data).then(() => {
      commit("deleteProgrammeV2", 'Le programme a bien été supprimé')
      commit("setErorsV2", '')
    }).catch((error) => {
      commit("deleteProgrammeV2",'')
      commit("setErorsV2", error.response.data.erreurs)
    })
  },
  importProgrammesV2({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL_V2+"importer-programmes",data).then(()=>{
      commit("singleProgramesV2", 'Le fichier a bien été importé')
    }).catch((error)=>{
      commit("setErorsV2", error.response.data.erreurs)
    })
  },
  getRecapProgrammeV2({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"programmes/"+data+"/recap").then((response) => {
      commit("setProgrammeRecapV2", response.data.programme_decomptes[0])
    }).catch((error) => {
      commit("setFailProgrammeRecapV2", error.response.data.code_message)
    })
  },
}
const mutations = {
  setProgrammesV2: (state, programmes) => (
    state.programmesV2 = programmes
  ),
  singleProgramesV2: (state, singleprogrammes) => (
    state.singleProgrammesV2 = singleprogrammes
  ),
  setUpdateProgrammeV2: (state, updateprogrammes) => (
    state.updateProgrammeMsgV2 = updateprogrammes
  ),
  deleteProgrammeV2: (state, deleteprogrammes) => (
    state.deleteProgrammemsgV2 = deleteprogrammes
  ),
  setErorsV2: (state, error) => (
    state.progerrorsV2 = error
  ),
  setMsgFailProgramV2: (state, msgfailprogram) => (
    state.msgFailProgramV2 = msgfailprogram
  ),
  singleProgrammeV2: (state, error) => (
    state.programmeV2 = error
  ),
  setRubriquesV2: (state, rubriques) => (
    state.progrubriquesV2 = rubriques
  ),
  setProgrammeV2: (state, programme) => (
    state.assignProgrammeV2 = programme
  ),
  setProgrammeRecapV2: (state, programme) => (
    state.programmeRecapV2 = programme
  ),
  setFailProgrammeRecapV2: (state, programme) => (
    state.failProgrammeRecapV2 = programme
  ),
}

export default {
    state,
    getters,
    actions,
    mutations
}
