<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        <router-link :to="'/programme'">
          Programme>
        </router-link>
        <span>Modification de programme</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Modification de programme
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="row "
           id="notification">
        <Notif :notif="notif" />
      </div>
      <form class="accordion" 
            id="accordionExample">
        <table class="table col-md-12 mx-auto">
          <thead class="card-header"
                 id="headingOne">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
              <th>Informations du programme </th>
            </tr>
          </thead>
          <div id="collapseOne"
               class="collapse show"
               aria-labelledby="headingOne"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="row g-3 align-items-center mx-4">
                <div class="col-md-3">
                  <label>Catégorie du programme</label>
                  <select class="form-control" 
                          name="" 
                          id=""
                          v-model="form.categorie_programme">
                    <option value="">Choisissez une catégorie</option>
                    <option v-for="(categorie, catKey) in categoriesProgramme"
                            :key="catKey"
                            :value="categorie.id">{{ categorie.libelle }}</option>
                  </select>
                  <span v-if="form.errors().has('categorie_programme') && affiche.categorie_programme !== 'categorie_cloture_programme'"
                        v-text="form.errors().get('categorie_cloture_programme')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-3">
                  <label class="req">Nom du programme</label>
                  <input type="text"
                         name="libelle"
                         v-model="form.libelle"
                         class="form-control form-control-lg"
                         v-on:click="removeSPan('libelle')"
                         aria-label=".form-control-lg example" />
                  <span v-if="form.errors().has('libelle') && affiche.libelle !== 'libelle'"
                        v-text="form.errors().get('libelle')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-3">
                  <label>Date de validation</label>
                  <input type="date"
                         name="libelle"
                         v-model="form.date_validation_programme"
                         class="form-control form-control-lg"
                         v-on:click="removeSPan('date_validation_programme')"
                         aria-label=".form-control-lg example" />
                  <span v-if="form.errors().has('date_validation_programme') && affiche.date_validation_programme !== 'date_validation_programme'"
                        v-text="form.errors().get('date_validation_programme')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-3">
                  <label class="req">Durée du programme (année)</label>
                  <input
                    type="number"
                    name="duree"
                    v-model="form.duree_programme"
                    :ref="'duree_programme'"
                    class="form-control form-control-lg"
                    v-on:click="removeSPan('duree_programme')"
                    @blur="calculDateFin()"
                    aria-label=".form-control-lg example"
                  />
                  <span v-if="form.errors().has('duree_programme') && affiche.duree_programme !== 'duree_programme'"
                        v-text="form.errors().get('duree_programme')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="row g-3 align-items-center mx-4">
                <div class="col-md-3">
                  <label class="req">Date début</label>
                  <input
                    type="date"
                    name="date_debut"
                    v-model="form.date_debut"
                    :ref="'date_debut'"
                    class="form-control form-control-lg"
                    v-on:click="removeSPan('date_debut')"
                    v-on:change="getDate()"
                    aria-label=".form-control-lg example"
                  />
                  <span v-if="form.errors().has('date_debut') && affiche.date_debut !== 'date_debut'"
                        v-text="form.errors().get('date_debut')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-3">
                  <label class="req">Date de fin (prévisionnelle)</label>
                  <input
                    type="date"
                    name="date_fin"
                    v-model="form.date_fin"
                    :ref="'date_fin'"
                    class="form-control form-control-lg"
                    disabled
                  />
                  <span v-if="form.errors().has('date_fin') && affiche.date_fin !== 'date_fin'"
                        v-text="form.errors().get('date_fin')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-3">
                  <label>Date de lancement</label>
                  <input
                    type="date"
                    name="dateLancement"
                    min="0"
                    max="999999999999"
                    v-model="form.date_lancement_programme"
                    class="form-control form-control-lg"
                    @change="checkbudget(form.date_lancement_programme)"
                    v-on:click="removeSPan('date_lancement_programme')"
                    aria-label=".form-control-lg example"
                  />
                  <span v-if="form.errors().has('date_lancement_programme') && affiche.date_lancement_programme !== 'date_lancement_programme'"
                        v-text="form.errors().get('date_lancement_programme')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-3">
                  <label>Date de clôture</label>
                  <input
                    type="date"
                    name="date_cloture"
                    v-model="form.date_cloture_programme"
                    :ref="'date_cloture_programme'"
                    class="form-control form-control-lg"
                    v-on:click="removeSPan('date_cloture_programme')"
                    v-on:change="getDate()"
                    aria-label=".form-control-lg example"
                  />
                  <span v-if="form.errors().has('date_cloture_programme') && affiche.date_cloture_programme !== 'date_cloture_programme'"
                        v-text="form.errors().get('date_cloture_programme')"
                        class="errorMsg"> </span>
                </div>
              
              </div>
              <div class="row g-3 align-items-center mx-4">
                
                <div class="col-md-3">
                  <label>Date de rapport de clôture</label>
                  <input
                    type="date"
                    name="date_transmission_rapport_cloture_programme"
                    v-model="form.date_transmission_rapport_cloture_programme"
                    :ref="'date_transmission_rapport_cloture_programme'"
                    class="form-control form-control-lg"
                    v-on:click="removeSPan('date_transmission_rapport_cloture_programme')"
                    v-on:change="getDate()"
                    aria-label=".form-control-lg example"
                  />
                  <span v-if="form.errors().has('date_transmission_rapport_cloture_programme') && affiche.date_transmission_rapport_cloture_programme !== 'date_transmission_rapport_cloture_programme'"
                        v-text="form.errors().get('date_transmission_rapport_cloture_programme')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-3">
                  <label class="req">Montant initial</label>
                  <input
                    type="text"
                    name="budget"
                    min="0"
                    max="999999999999"
                    v-model="montant_initial_programme"
                    class="form-control form-control-lg"
                    @input="addSpace('montant_initial_programme')"
                    v-on:click="removeSPan('montant_initial_programme')"
                    aria-label=".form-control-lg example"
                    disabled
                  />
                  <span v-if="form.errors().has('montant_initial_programme') && affiche.montant_initial_programme !== 'montant_initial_programme'"
                        v-text="form.errors().get('montant_initial_programme')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-3">
                  <label>Montant révisé</label>
                  <input
                    type="text"
                    name="budget"
                    min="0"
                    max="999999999999"
                    v-model="montant_revise"
                    class="form-control"
                    v-on:click="removeSPan('montant_revise')"
                    @input="addSpace('montant_revise')"
                    @change="checkbudget(montant_revise)"
                    aria-label=" example"
                  />
                  <span v-if="form.errors().has('montant_revise') && affiche.montant_revise !== 'montant_revise'"
                        v-text="form.errors().get('montant_revise')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-3">
                  <label>Montant définitif</label>
                  <input
                    type="text"
                    name="budget"
                    min="0"
                    max="999999999999"
                    v-model="montant_definitif"
                    class="form-control"
                    v-on:click="removeSPan('montant_definitif')"
                    @input="addSpace('montant_definitif')"
                    aria-label=" example"
                    disabled
                  />
                  <span v-if="form.errors().has('montant_definitif') && affiche.montant_definitif !== 'montant_definitif'"
                        v-text="form.errors().get('montant_definitif')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="row g-3 align-items-center mx-4">
              
              </div>
              <div class="row g-3 align-items-center mx-4">
                <div class="col-md-12">
                  <label>Description</label>
                  <textarea class="form-control"
                            id="exampleFormControlTextarea1"
                            name="description"
                            v-model="form.description"
                            v-on:click="removeSPan('description')"
                            rows="3"></textarea>
                  <span v-if="form.errors().has('description') && affiche.description !== 'description'"
                        v-text="form.errors().get('description')"
                        class="errorMsg"> </span>
                </div>
              </div>      
            </div>
          </div> 
        </table>
        <template v-if="ordonnancements.length == form.duree_programme">
          <table class="table col-md-12 mx-auto"
                 v-for="(ordonnancement,n) in ordonnancements" 
                 :key="'ord'+n">
            <thead class="card-header"
                   :id="'heading'+n">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target:="#collapseTwo"
                  aria-expanded="true"
                  aria-control:s="collapseTwo"
                  @click="ToggleContent('heading'+n)">
                <th>Ordonnancement {{ ordonnancement.date_debut.split("-")[0] }}  </th>
              </tr>
            </thead>
            <div id="collapseTwo"
                 :ref="'heading'+n"
                 class="collapse show"
                 aria-labelled:by="headingTwo"
                 data-parent="#accordionExample">
              <OrdonnancementUpdateForm :index="Number(n)"
                                        :listrubriques="rubriqueList"
                                        :ordActivite="activiteOrdonnancement"/>
            </div>
          </table>
        </template>
        
        <div class="button_form programBtn">
          <button type="button"
                  class="btn btn-f-blue"
                  @click="submit()"><i class="flaticon flaticon-floppy-disk"></i> Enregistrer</button>
          <button type="reset"
                  class="btn btn-f-blue"
                  data-toggle="modal"
                  data-target="#exampleModal"><i class="flaticon flaticon-cancel"></i> &nbsp;&nbsp; Annuler</button>
        </div>
      
      </form>
    </div>
  </div>
</template>
<style>
@import "./programme.css";
#notification {
  position: center;
}
form div.col-md-3 select.form-control{
  font-size: 9.6px;
}
/* form div.col-md-3 select.form-control option{
  font-size: 0.9em;
} */
form div.col-md-3 select.form-control,
form div.col-md-3 input[type="text"],
form div.col-md-3 input[type="date"],
form div.col-md-3 input[type="number"],
form textarea
{
  height: fit-content;
}
.tableview .col-md-6 > span.act-libelle{
  text-decoration: capitalize;
}
</style>
<script>
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
// import ProgrammeForm from "./CreateProgrammeForm.vue"
import OrdonnancementUpdateForm from "./UpdateOrdonnancementForm.vue"
import { mapActions, mapMutations, mapGetters } from "vuex"
import utils from "@/assets/js/utils"
export default {
  name: "ModifyProgramme",
  components: {
    Notif,
    // ProgrammeForm,
    OrdonnancementUpdateForm,
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    montant_initial_programme:"",
    montant_revise:"",
    montant_definitif:"",
    activites: [],
    categoriesProgramme:[],
    form: form({
      id:"",
      libelle: "",
      montant_initial_programme: "",
      montant_revise: "",
      montant_definitif: "",
      date_debut: "",
      date_fin: "",
      description: "",
      date_validation_programme: "",
      date_lancement_programme: "",
      date_transmission_rapport_cloture_programme: "",
      date_cloture_programme: "",
      duree_programme:"",
      categorie_programme: "",
      activites: []
    }).rules({
      libelle: "required",
      montant_initial_programme: "required",
      date_debut: "required",
      date_fin: "required",
    }),
    formData: {
      id:"",
      libelle: "",
      montant_initial_programme: "",
      montant_revise: "",
      montant_definitif: "",
      date_debut: "",
      date_fin: "",
      description: "",
      annee_exercice:"",
      date_validation_programme: "",
      date_lancement_programme: "",
      date_transmission_rapport_cloture_programme: "",
      date_cloture_programme: "",
      categorie_programme: "",
      activites: []
    },
    nb_ordonnancements:0,
    countval: 0,
    send: false,
    ordonnancements:[],
    activitesList: [],
    rubriqueList:[],
    affiche: {
      libelle: "libelle:true",
      montant_initial_programme: "montant_initial_programme:true",
      date_debut: "date_debut:true",
      date_fin: "date_fin:true",
      description: "description:true",
      date_validation_programme: "date_validation_programme:true",
      date_lancement_programme: "date_lancement_programme:true",
      date_transmission_rapport_cloture_programme: "date_transmission_rapport_cloture_programme:true",
      date_cloture_programme: "date_cloture_programme:true",
    },
    gestion_date_debut: "date_debut",
    gestion_date_fin: "date_fin",
    activiteOrdonnancement:[]
  }),
  watch: {
    ["form.data"]: {
      deep: true,
      immediate: false,
      handler: "onFormChange"
    },
    progrubriques() {
      if (this.progrubriques) {
        const rubriqueArr = []
        this.activiteOrdonnancement = []
        this.progrubriques.donnees.forEach(rubrique => {
          if (rubrique.categorie_programme == this.form.categorie_programme) {
            rubriqueArr.push(rubrique)
            this.activiteOrdonnancement.push(rubrique)
          }
        })
        this.rubriqueList = rubriqueArr
      }     
    },
    updateProgrammeMsgV2() {
      if (this.updateProgrammeMsgV2) {
        console.log("in updateProgramme")
        // this.ordonnancements.forEach(ordonnancement => {
        //   ordonnancement.v2_programme=this.updateProgrammeMsgV2
        // })
        this.putOrdonnancement(this.ordonnancements)
      }
    },
    msgSuccessOrdonnancement() {
      if (this.msgSuccessOrdonnancement) {
        this.notif.message = "Le programme a bien été modifié"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgSuccessOrdonnancement("")
            this.$router.push({ name: "Programme" })
          }.bind(this),
          3000
        )
      }
    },
    msgFailOrdonnancement() {
      if (this.msgFailOrdonnancement) {
        this.notif.message = this.msgFailOrdonnancement
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailOrdonnancement("")
          }.bind(this),
          3000
        )
      }
    },
    msgFailProgramV2(){
      if (this.msgFailProgramV2) {
        this.notif.message = this.msgFailProgramV2
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailProgramV2("")
          }.bind(this),
          3000
        )
      }
    },
    listCategories(){
      if (this.listCategories) {
        this.categoriesProgramme=this.listCategories.donnees
      }
    },
    assignProgrammeV2() {
      // var counter = 0
      // var listActivities = []
      console.log("in")
      
      if (this.assignProgrammeV2.programme) {
        this.form.id = this.$route.params.id
        console.log(this.form.id)
        
        this.form.libelle = this.assignProgrammeV2.programme.nom_programme
        this.montant_initial_programme = "" + Math.round(this.assignProgrammeV2.programme.montant_initial_programme)
        this.montant_revise ="" + Math.round(this.assignProgrammeV2.programme.montant_revise_programme)
        this.addSpace("montant_revise")
        this.addSpace("montant_initial_programme")
        this.montant_definitif =""+ Math.round(
          Number(this.assignProgrammeV2.programme.montant_initial_programme) + 
            Number(this.assignProgrammeV2.programme.montant_revise_programme) 
        )
        this.addSpace("montant_definitif")
        this.form.date_debut = this.assignProgrammeV2.programme.date_debut
        this.form.date_fin = this.assignProgrammeV2.programme.date_fin
        this.form.date_fin = this.assignProgrammeV2.programme.date_fin
        this.form.date_validation_programme = this.assignProgrammeV2.programme.date_validation_programme
        this.form.date_lancement_programme = this.assignProgrammeV2.programme.date_lancement_programme
        this.form.date_transmission_rapport_cloture_programme = this.assignProgrammeV2.programme.date_transmission_rapport_cloture_programme
        this.form.date_cloture_programme = this.assignProgrammeV2.programme.date_cloture_programme
        // this.form.annee_exercice = this.assignProgrammeV2.programme.annee_exercice
        this.form.description = this.assignProgrammeV2.programme.description
        this.form.categorie_programme = this.assignProgrammeV2.programme.categorie_programme
        this.form.duree_programme = this.assignProgrammeV2.programme.ordonnancements.length
        this.assignProgrammeV2.programme.ordonnancements.forEach(ordonnancement => {
          this.ordonnancements.push(
            {
              v2_programme : this.$route.params.id,
              id:ordonnancement.id,
              libelle : ordonnancement.libelle,
              description : ordonnancement.description,
              montant_initial : Math.round(Number(ordonnancement.montant_initial)),
              montant_revise : Math.round(Number(ordonnancement.montant_revise)),
              date_debut : ordonnancement.date_debut,
              date_fin : ordonnancement.date_fin,
              activites : this.activites
            }
          )
        })
        this.getRubriques()

      }
    },
  },
  computed: {
    ...mapGetters(["progerrorsV2", "assignProgrammeV2","updateProgrammeMsgV2", "msgFailProgramV2","listCategories","failListCategorie","progrubriques","msgSuccessOrdonnancement","msgFailOrdonnancement"])
  },
  created() {
    this.getListCategories()
    if (this.$route.params.id) {
      this.getProgrammeV2(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions(["updateProgrammesV2","getProgrammeV2","getListCategories", "getRubriques","putOrdonnancement"]),
    ...mapMutations(["singleProgramesV2", "setErorsV2","setMsgFailProgramV2","setFailListCategorie","setListCategories","setMsgSuccessOrdonnancement","setMsgFailOrdonnancement"]),

    onFormChange() {
      //this.form.validate()
    },

    checked(id) {
      if (this.$refs["mont" + id][0].value == "0") {
        this.enabled(id)
        this.$refs["check" + id][0].checked = false
      }
      if (this.$refs["mont" + id][0].value.length > 15) {
        this.notif.message = "Le montant ne doit pas être supérieur à 999.999.999.999"
        this.notif.type = "toast-warning"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    enabled(id) {
      if (this.$refs["obs" + id][0].disabled) {
        this.$refs["obs" + id][0].disabled = false
        this.$refs["mont" + id][0].disabled = false
      } else {
        this.$refs["obs" + id][0].value = ""
        this.$refs["obs" + id][0].disabled = true
        this.$refs["mont" + id][0].value = 0
        this.$refs["mont" + id][0].disabled = true
        this.activites.forEach(activite => {
          if (activite.id == id) {
            activite.montant = 0
            activite.observation = ""
          }
        })
      }
    },
    submit() {
      this.formData.id = this.form.data.id
      this.formData.libelle = this.form.data.libelle
      this.formData.montant_initial_programme = this.form.data.montant_initial_programme
      this.formData.montant_revise = this.form.data.montant_revise
      this.formData.montant_definitif = this.form.data.montant_definitif
      this.formData.date_debut = this.form.data.date_debut
      this.formData.date_fin = this.form.data.date_fin
      this.formData.description = this.form.data.description
      this.formData.date_validation_programme = this.form.data.date_validation_programme
      this.formData.date_lancement_programme = this.form.data.date_lancement_programme
      this.formData.date_transmission_rapport_cloture_programme = this.form.data.date_transmission_rapport_cloture_programme
      this.formData.date_cloture_programme = this.form.data.date_cloture_programme
      // this.formData.annee_exercice = this.form.data.annee_exercice
      this.formData.categorie_programme = this.form.data.categorie_programme
      
      this.activitesList = []
      this.form.validate()
      // Verifier si le montant_total_activite <= montant_initial_programme
      var montant_total_activite=0
      var montant_total_ordonnancement=0
      this.activites.forEach(activiteSup => {
        activiteSup.forEach( activite => {
          if (activite.montant_revise.length > 3) {
            if (activite.montant_revise.includes(" ")) {
              var montant= activite.montant_revise.split(" ")
              var result=""
              for (let index = 0; index < montant.length; index++) {
                result+=montant[index]
              }
              montant_total_activite+=Number(result)+Number(activite.montant)
            }
          }else{
            montant_total_activite+=Number(activite.montant_revise)+Number(activite.montant)
          }
        }) 
      })
      this.ordonnancements.forEach(ordonnancement => {
        montant_total_ordonnancement+=Number(ordonnancement.montant_initial) + Number(ordonnancement.montant_revise) 
      })

      if (montant_total_ordonnancement <= Number(this.formData.montant_definitif)) {
        if (montant_total_activite <= montant_total_ordonnancement) {
          if (
            !this.form
              .validate()
              .errors()
              .any()
          ) {
            var count=0
            this.activites.forEach(activiteSup => {
              activiteSup.forEach(activite => {
                if (activite.observation != "" && activite.montant_revise != 0) {
                  if (activite.montant_revise.includes(" ")) {
                    var montant= activite.montant_revise.split(" ")
                    var res=""
                    for (let index = 0; index < montant.length; index++) {
                      res+=montant[index]
                    }
                    activite.montant=Number(res)
                  }
                  this.activitesList.push(activite)
                }
                
              })
              
              console.log(this.ordonnancements)
              this.ordonnancements[count].activites = this.activitesList
              count++
            })
            this.send = true
            this.ordonnancements.forEach(ordonnancement => {
              ordonnancement.activites.forEach(activite=>{
                if (activite.observation == "" && activite.montant_revise != 0) {
                  this.send = false
                  var message = "Veuillez remplir tous les champs observation"
                  this.notif.message = message
                  this.notif.type = "toast-warning"
                  this.notif.activated = "activated"
                  setTimeout(
                    function() {
                      this.notif.activated = ""
                    }.bind(this),
                    3000
                  )
                }
              })
            })
            if (this.send) {
              this.updateProgrammesV2(this.formData)
            }
          } else {
            for (let afficheKey in this.affiche) {
              const tabVale = this.affiche[afficheKey].split(":")
              if (tabVale.length === 1) {
                this.affiche[afficheKey] = tabVale[0] + ":true"
              }
            }
          }
        }else{
          var message = "Le montant total des activités doit être inférieur aux montants initiaux des ordonnancements"
          this.notif.message = message
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            3000
          )
        }  
      }else{
        message = "Le montant total des ordonnancements doit être inférieur au montant initial du programme"
        this.notif.message = message
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
      
    },
    goBack() {
      this.$router.push({ name: "Programme" })
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
    checkbudget(budget) {
      if (budget > 999999999999) {
        var message = "Veuillez renseigner un montant inférieur à 999.999.999.999"
        this.notif.message = message
        this.notif.type = "toast-warning"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }else{
        this.montant_definitif = Number(this.form.montant_initial_programme)+Number(this.form.montant_revise)
        this.montant_definitif+=""
        this.addSpace("montant_definitif")
        
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    calculDateFin(){
      this.form.date_fin=Number(this.form.date_debut.split("-")[0]) + Number(this.form.duree_programme)+"-"
      this.form.date_fin += this.form.date_debut.split("-")[1] + "-"
      this.form.date_fin += this.form.date_debut.split("-")[2]
      this.ordonnancements=[]
      this.activitess=[]
      for (let index = 0; index < Number(this.form.duree_programme); index++) {
        this.ordonnancements.push(
          {
            v2_programme : "",
            libelle : "Ordonnancement ",
            description : "",
            montant_initial : 0,
            montant_revise : 0,
            date_debut : Number(this.form.date_debut.split("-")[0]) + Number(index)+"-"
              +this.form.date_debut.split("-")[1] + "-" 
              + this.form.date_debut.split("-")[2],
            date_fin : Number(this.form.date_debut.split("-")[0]) + Number(index+1) +"-"
              +this.form.date_debut.split("-")[1] + "-" 
              + this.form.date_debut.split("-")[2],
            activites : this.activites
          }
        )
        this.ordonnancements[index].libelle += Number(this.form.date_debut.split("-")[0]) + Number(index)
        this.activites.push([])
        console.log(this.ordonnancements)
      }
      this.getRubriques()

    },
    ToggleContent(key){
      console.log(this.$refs[key][0])
    },
    /**
     * voir dans src/assets/js/utils
     */
    getDate() {
      utils.getDate(this, this.gestion_date_debut, this.gestion_date_fin, "programme")
    }
  }
}
</script>
