import HTTP from './../../Api/auth'
const state = {
  ordonnancements:[],
  msgFailOrdonnancements:"",
  ordonnancementsSP:[],
  msgFailOrdonnancementsSP:"",
  ordonnancement:"",
  msgFailOrdonnancement:"",
  msgSuccessOrdonnancement:"",
}

const getters = {
  ordonnancements: state => state.ordonnancements,
  msgFailOrdonnancements: state => state.msgFailOrdonnancements,
  ordonnancementsSP: state => state.ordonnancementsSP,
  msgFailOrdonnancementsSP: state => state.msgFailOrdonnancementsSP,
  ordonnancement: state => state.ordonnancement,
  msgFailOrdonnancement: state => state.msgFailOrdonnancement,
  msgSuccessOrdonnancement: state => state.msgSuccessOrdonnancement,
}
const actions = {
  getOrdonnancements({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"ordonnancement-programmes").then((response) => {
      commit("setOrdonnancements", response.data)
    }).catch((error) => {
      commit("setMsgFailOrdonnancements", error.response.data.code_message)
    })
  },
  getOrdonnancementsSP({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"ordonnancement-programmes-sans-pagination").then((response) => {
      commit("setOrdonnancementsSP", response.data)
    }).catch((error) => {
      commit("setMsgFailOrdonnancementsSP", error.response.data.code_message)
    })
  },
  getOrdonnancement({commit},data){
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"ordonnancement-programmes/"+data.id).then((response) => {
      commit("setOrdonnancement", response.data)
    }).catch((error) => {
      commit("setMsgFailOrdonnancement", error.response.data.code_message)
    })
  },
  postOrdonnancement({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL_V2+"ordonnancement-programmes",{ordonnancements:data}).then((response) => {
      commit("setMsgSuccessOrdonnancement", response.data)
    }).catch((error) => {
      commit("setMsgFailOrdonnancement", error.response.data.code_message)
    })
  },
  putOrdonnancement({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL_V2+"ordonnancement-programmes/"+data.id,data).then((response) => {
      commit("setMsgSuccessOrdonnancement", response.data)
    }).catch((error) => {
      commit("setMsgFailOrdonnancement", error.response.data.code_message)
    })
  },
  deleteOrdonnancement({commit},data){
    HTTP.delete(process.env.VUE_APP_AUTHURL_V2+"ordonnancement-programmes/"+data.id, data).then((response) => {
      commit("setMsgSuccessOrdonnancement", response.data)
    }).catch((error) => {
      commit("setMsgFailOrdonnancement", error.response.data.erreurs)
    })
  },
}
const mutations = {
  setOrdonnancements: (state, ordonnancements) => (
    state.ordonnancements = ordonnancements
  ),
  setMsgFailOrdonnancements: (state, ordonnancements) => (
    state.msgFailOrdonnancements = ordonnancements
  ),
  setOrdonnancementsSP: (state, ordonnancements) => (
    state.ordonnancementsSP = ordonnancements
  ),
  setMsgFailOrdonnancementsSP: (state, ordonnancements) => (
    state.msgFailOrdonnancementsSP = ordonnancements
  ),
  setOrdonnancement: (state, ordonnancement) => (
    state.ordonnancement = ordonnancement
  ),
  setMsgFailOrdonnancement: (state, ordonnancement) => (
    state.msgFailOrdonnancement = ordonnancement
  ),
  setMsgSuccessOrdonnancement: (state, ordonnancement) => (
    state.msgSuccessOrdonnancement = ordonnancement
  ),
}

export default {
    state,
    getters,
    actions,
    mutations
}
