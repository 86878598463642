<template>
  <div>
    <form class="form_default">
      <div class="form-group">
        <label  class="req">Libellé</label>
        <input
          type="text"
          name="libelle"
          v-model='form.libelle'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label for="">Type de programme</label>
        <select class="form-control" 
                name="" 
                id=""
                v-model="form.categorie_programme">
          <option value="">Veuillez choisir le type de programme</option>
          <option v-for="(cat, catkey) in categorie"
                  :key="catkey"
                  :value="cat.id">{{ cat.libelle }}</option>
        </select>
      </div>
      <div class="button_form">
        <button
          type="button"
          class="btn btn-f-blue enter_btn"
          @click='submit'>
          <i class="flaticon-floppy-disk"></i> Enregistrer
        </button>
        <button
          type="reset"
          class="btn btn-f-blue cancle_btn ml-4">
          <i class="flaticon-cancel"></i> Annuler
        </button>
      </div>
    </form>
  </div>
</template>
<style>
  @import './rubriques.css';
</style>
<script>
import form from 'vuejs-form'
import {mapActions,mapGetters} from "vuex"
export default {
  name: "CreateRubriques",
  props:["categorie"],
  components:{
  },
  data: () => ({
    form: form({
      libelle: '',
      categorie_programme:"",
      description: ''
    })
      .rules({
        libelle: 'required',
      })
      .messages({
        'libelle.libelle': 'Champ requis!',
      })
  }),
  watch: {
    ['form.data']: {
      deep: true,
      immediate: false,
      handler: 'onFormChange'
    },
    singleRubriques(){
      if (this.singleRubriques) {
        this.$emit("rubrique",{id:this.singleRubriques.data.id,type:"fromrub"})
      }
    }
  },
  computed: {
    ...mapGetters(['ruberrors','singleRubriques',])
  },
  methods: {
    ...mapActions(["saveRubriques",]),
    onFormChange() {
      this.form.validate()
    },
    submit() {
      if (!this.form.validate().errors().any()) {
        this.saveRubriques(this.form.data)
      }
    }
  }
}
</script>
