<template>
  <div class="col_tablev2 row">
    <!-- <div class="col-12"> -->
    <form class="col-12">
      <div class="my-3">
        <div class="row g-3 align-items-center mx-4">
          <div class="col-md-3">
            <label class="req">Libellé</label>
            <input type="text"
                   name="libelle"
                   v-model="$parent.ordonnancements[index].libelle"
                   class="form-control form-control-lg"
                   v-on:click="removeSPan('libelle')"
                   aria-label=".form-control-lg example" />
          </div>
          <div class="col-md-3">
            <label class="req">Montant initial</label>
            <input
              type="text"
              name="budget"
              min="0"
              max="999999999999"
              v-model="montant_initial"
              class="form-control form-control-lg"
              aria-label=".form-control-lg example"
              @input="addSpace('montant_initial')"
              @change="checkbudgetOrdonnancement($parent.ordonnancements[index].montant_initial)"
            />
          </div>
          <div class="col-md-3">
            <label class="req">Date début</label>
            <input
              type="date"
              name="date_debut"
              v-model="$parent.ordonnancements[index].date_debut"
              :ref="'date_debut'"
              class="form-control form-control-lg"
              aria-label=".form-control-lg example"
              disabled
            />
          </div>
          <div class="col-md-3">
            <label class="req">Date de fin (prévisionnelle)</label>
            <input
              type="date"
              name="date_fin"
              v-model="$parent.ordonnancements[index].date_fin"
              :ref="'date_fin'"
              class="form-control form-control-lg"
              disabled
            />
          </div>
        </div>
        <div class="row g-3 align-items-center mx-4">
          <div class="col-md-12">
            <label>Description</label>
            <textarea class="form-control"
                      id="exampleFormControlTextarea1"
                      name="description"
                      v-model="$parent.ordonnancements[index].description"
                      v-on:click="removeSPan('description')"
                      rows="3"></textarea>
          </div>
        </div>      
      </div>
    </form>
    <!-- </div> -->
    <div class="col_header col-12">
      <div class="row">
        <div class="col-6">DESIGNATION</div>
        <div class="col-4 text-center">OBSERVATION</div>
        <div class="col-2 text-center">MONTANT</div>
      </div>
    </div>
    <div class="col-12"
         v-if="pret">
      <div class="row no-mr"
           v-for="(rubrique, rubkey) in listrubriques"
           :key="rubkey">
        <div class="col-md-12"
             v-if="$parent.activites.length > 0">
          <div class="row light_row">
            <div class="col-md-12">
              <label>{{ rubrique.libelle }}</label>
            </div>
          </div>
          <div class="row alter tableview"
               v-for="(activite, actkey) in rubrique.activites"
               :key="actkey">
            <div class="col-md-6 ">
              <input type="checkbox"
                     :ref="'check' + activite.id"
                     @click="enabled(activite.id)" />
              <span class="act-libelle">&nbsp; &nbsp;{{ activite.libelle }}</span>
            </div>
            <div class="col-md-4 tb_row">
              <textarea :ref="'ord'+index+'obs' + activite.id"
                        class="form-control"
                        disabled
                        v-model="$parent.activites[index][activite.counter].observation"></textarea>
            </div>
            <div class="col-md-2 tb_row">
              <input
                @change="checked(activite.id)"
                @input="addSpaceActivity(activite.counter)"
                type="text"
                :ref="'ord'+index+'mont' + activite.id"
                class="form-control"
                disabled
                v-model="$parent.activites[index][activite.counter].montant"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
</template>
<style>
/* @import "./programme.css"; */
#notification {
  position: center;
}
form div.col-md-3 select.form-control{
  font-size: 9.6px;
}
/* form div.col-md-3 select.form-control option{
  font-size: 0.9em;
} */
form div.col-md-3 select.form-control,
form div.col-md-3 input[type="text"],
form div.col-md-3 input[type="date"],
form div.col-md-3 input[type="number"],
form textarea
{
  height: fit-content;
}
.tableview .col-md-6 > span.act-libelle{
  text-decoration: capitalize;
}
</style>
<script>
import form from "vuejs-form"
import { mapActions, mapMutations, mapGetters } from "vuex"
import utils from "@/assets/js/utils"
export default {
  name: "OrdonnancementForm",
  components: {
    // Notif
  },
  props:["index","listrubriques","ordActivite"],
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    montant_initial:"",
    montant_revise:"",
    montant_definitif:"",
    activites: [],
    formOrdonnancement: form({
      libelle: "",
      montant_initial: "",
      montant_revise: "",
      date_debut: "",
      date_fin: "",
      description: "",
      v2_programme: "",
      activites: []
    }).rules({
      libelle: "required",
      montant_initial: "required",
      date_debut: "required",
      date_fin: "required",
    }),
    pret:false,
    countval: 0,
    send: false,
    activitesList: [],
    gestion_date_debut: "date_debut",
    gestion_date_fin: "date_fin"
  }),
  watch: {
    ["form.data"]: {
      deep: true,
      immediate: false,
      handler: "onFormChange"
    },
    listrubriques(){
      console.log(this.listrubriques)
      
    },
    singleProgrammes() {
      if (this.singleProgrammes) {
        // console.log(this.singleProgrammes)
        this.notif.message = this.singleProgrammes
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.singleProgrames("")
            this.$router.push({ name: "Programme" })
            // location.reload()
          }.bind(this),
          3000
        )
      }
    },
    msgFailProgram(){
      if (this.msgFailProgram) {
        this.notif.message = this.msgFailProgram
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailProgram("")
          }.bind(this),
          3000
        )
      }
    },
    ordActivite(){
      const rubArrray=this.ordActivite
      var counter = 0
      var listActivities = []
      var listAct = []    
      rubArrray.filter(function(actArr, rubindex) {
        actArr.activites.filter(function(activity, actindex) {
          const insertActivity = { id: "", observation: "", montant: "" }
          insertActivity.id = activity.id
          insertActivity.observation = activity.observation
          insertActivity.montant = activity.montant_budgete
          listActivities.push(insertActivity)
          console.log(rubindex)
          listAct.push(insertActivity)
          rubArrray[rubindex].activites[actindex]["counter"] = counter
          counter += 1
        })
      })
      const donnees = listActivities
      const activitesDonnees = listAct
      this.$parent.ordonnancements[this.index].activites = donnees
      this.$parent.activites[this.index] = activitesDonnees
      console.log(this.$parent.activites[this.index])
      console.log(this.$parent.ordonnancements[this.index].activites)
      this.pret=true 
    }
  },
  computed: {
    ...mapGetters(["progerrors", "singleProgrammes", "msgFailProgram"])
  },
  created() {
    
  },
  methods: {
    ...mapActions(["saveProgrammes"]),
    ...mapMutations(["singleProgrames", "setErors","setMsgFailProgram"]),

    onFormChange() {
      //this.form.validate()
    },

    checked(id) {

      if (this.$refs["ord"+this.index+"mont" + id][0].value == "0") {
        this.enabled(id)
        this.$refs["ord"+this.index+"check" + id][0].checked = false
      }
      if (this.$refs["ord"+this.index+"mont" + id][0].value.length > 15) {
        this.notif.message = "Le montant ne doit pas être supérieur à 999.999.999.999"
        this.notif.type = "toast-warning"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }else{
        // Verifier si le montant_total_activite <= montant_initial_programme
        
        var montant_total_activite=0
        this.$parent.activites[this.index].forEach(activite => {

          if (activite.montant.length > 3) {
            if (activite.montant.includes(" ")) {
              var montant= activite.montant.split(" ")
              var result=""
              for (let index = 0; index < montant.length; index++) {
                result+=montant[index]
              }
              montant_total_activite+=Number(result)
            }
          }else{
            montant_total_activite+=Number(activite.montant)
          }

        })
        if (montant_total_activite > this.$parent.ordonnancements[this.index].montant_initial) {

          var message = "Le montant total des activités doit être inférieur au montant initial de l'ordonnancement"
          this.$parent.notif.message = message
          this.$parent.notif.type = "toast-danger"
          this.$parent.notif.activated = "activated"
          setTimeout(
            function() {
              this.$parent.notif.activated = ""
            }.bind(this),
            3000
          )
        }

      }
    },
    enabled(id) {
      if (this.$refs["ord"+this.index+"obs" + id][0].disabled) {
        this.$refs["ord"+this.index+"obs" + id][0].disabled = false
        this.$refs["ord"+this.index+"mont" + id][0].disabled = false
      } else {
        this.$refs["ord"+this.index+"obs" + id][0].value = ""
        this.$refs["ord"+this.index+"obs" + id][0].disabled = true
        this.$refs["ord"+this.index+"mont" + id][0].value = 0
        this.$refs["ord"+this.index+"mont" + id][0].disabled = true
        this.$parent.activites[this.index].forEach(activite => {
          if (activite.id == id) {
            activite.montant = 0
            activite.observation = ""
          }
        })
      }
    },
    submit() {
      
      this.activitesList = []
      this.form.validate()
      // Verifier si le montant_total_activite <= montant_initial_programme
      var montant_total_activite=0
      this.activites.forEach(activite => {
        
        if (activite.montant.length > 3) {
          if (activite.montant.includes(" ")) {
            var montant= activite.montant.split(" ")
            var result=""
            for (let index = 0; index < montant.length; index++) {
              result+=montant[index]
            }
            montant_total_activite+=Number(result)
          }
        }else{
          montant_total_activite+=Number(activite.montant)
        }
        
      })
      if (montant_total_activite <= this.formData.montant_initial_programme) {
        if (
          !this.form
            .validate()
            .errors()
            .any()
        ) {
          this.activites.forEach(activite => {
            if (activite.observation != "" && activite.montant != 0) {
              if (activite.montant.includes(" ")) {
                var montant= activite.montant.split(" ")
                var res=""
                for (let index = 0; index < montant.length; index++) {
                  res+=montant[index]
                }
                activite.montant=Number(res)
              }
              this.activitesList.push(activite)
            }
          })
          this.send = true
          this.form.activites.forEach(activite => {
            if (activite.observation == "" && activite.montant != 0) {
              this.send = false
              var message = "Veuillez remplir tous les champs observation"
              this.notif.message = message
              this.notif.type = "toast-warning"
              this.notif.activated = "activated"
              setTimeout(
                function() {
                  this.notif.activated = ""
                }.bind(this),
                3000
              )
            }
          })
          if (this.send) {
            this.formData.activites = this.activitesList
            if (this.formData.activites.length != 0) {
              this.saveProgrammes(this.formData)
            } else {
              var message = "Veuillez ajouter au moins une activité"
              this.notif.message = message
              this.notif.type = "toast-warning"
              this.notif.activated = "activated"
              setTimeout(
                function() {
                  this.notif.activated = ""
                }.bind(this),
                3000
              )
            }
          }
        } else {
          for (let afficheKey in this.affiche) {
            const tabVale = this.affiche[afficheKey].split(":")
            if (tabVale.length === 1) {
              this.affiche[afficheKey] = tabVale[0] + ":true"
            }
          }
        }  
      }else{
        message = "Le montant total des activités doit être inférieur au montant initial du programme"
        this.notif.message = message
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
      
    },
    goBack() {
      this.$router.push({ name: "Programme" })
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
    checkbudgetOrdonnancement(budget) {
      if (budget > 999999999999) {
        var message = "Veuillez renseigner un montant inférieur à 999.999.999.999"
        this.$parent.notif.message = message
        this.$parent.notif.type = "toast-warning"
        this.$parent.notif.activated = "activated"
        setTimeout(
          function() {
            this.$parent.notif.activated = ""
          }.bind(this),
          3000
        )
      }else{
        var totalOrdonnancement=0
        this.$parent.ordonnancements.forEach(ordonnancement => {
          totalOrdonnancement+=ordonnancement.montant_initial
        })
        if (totalOrdonnancement > this.$parent.form.montant_initial_programme) {
          message = "le montant des ordonnancements ne doit pas dépasser celui du programme"
          this.$parent.notif.message = message
          this.$parent.notif.type = "toast-warning"
          this.$parent.notif.activated = "activated"
          setTimeout(
            function() {
              this.$parent.notif.activated = ""
            }.bind(this),
            3000
          )
        }
      }
    },
    checkbudgetOrdActivites(budget) {
      if (budget > 999999999999) {
        var message = "Veuillez renseigner un montant inférieur à 999.999.999.999"
        this.$parent.notif.message = message
        this.$parent.notif.type = "toast-warning"
        this.$parent.notif.activated = "activated"
        setTimeout(
          function() {
            this.$parent.notif.activated = ""
          }.bind(this),
          3000
        )
      }else{
        var totalOrdActivites=0
        this.$parent.activites[this.index].forEach(activite => {
          totalOrdActivites+=activite.montant
        })
        if (totalOrdActivites > this.$parent.ordonnancements[this.index].montant_initial) {
          message = "le montant des activités ne doit pas dépasser celui de l'ordonnancement"
          this.$parent.notif.message = message
          this.$parent.notif.type = "toast-warning"
          this.$parent.notif.activated = "activated"
          setTimeout(
            function() {
              this.$parent.notif.activated = ""
            }.bind(this),
            3000
          )
        }
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.$parent.ordonnancements[this.index][key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.$parent.ordonnancements[this.index][key] += res[index]
      }
      this.$parent.ordonnancements[this.index][key]=Number(this.$parent.ordonnancements[this.index][key])
    },
    //pour les montants activités
    addSpaceActivity(key) {
      var chaine = ""
      var splinter = ""
      splinter = this.$parent.activites[this.index][key].montant.split("")
      // var res =""
      if (/[0-9]/.test(this.$parent.activites[this.index][key].montant) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this.$parent.activites[this.index][key].montant)) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index]=""
          }
          chaine += splinter[index]

        }
        if (splinter.length >= 4) {
          // check if there's a space
          if (splinter.includes(" ")) {
            console.log("in")
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            trimer = chaine
            chaine = ""
            chaine = trimer.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      
      this.$parent.activites[this.index][key].montant = chaine
    },
    /**
     * voir dans src/assets/js/utils
     */
    getDate() {
      utils.getDate(this, this.gestion_date_debut, this.gestion_date_fin, "programme")
    }
  }
}
</script>
