<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        Programme >
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des programmes
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="inner_container">
      <div class="table_action">
        <div class="div_left">
          <router-link v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                       :to="{ path: '/programme/createProgramme' }"
                       class="btn btn-blue">
            Ajouter un programme
          </router-link>
        </div>
        <div class="div_left">
          <router-link :to="{ path: '/filtre' }"
                       class="btn btn-blue">
            Filtrer
          </router-link>
        </div>
        <!-- <div class="div_left">
          <button class="btn btn-blue pl-5 pr-5 op-btn"
                  data-toggle="modal"
                  ref="modal_button"
                  data-target="#filtreModal">Filtrer</button>
        </div> -->
        <div class="div_left"
             v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')">
          <button class="btn btn-blue pl-5 pr-5 op-btn"
                  data-toggle="modal"
                  ref="modal_button"
                  data-target="#exampleModal">Importer données historiques</button>
        </div>
        <div class="div_left">
          <button class="btn btn-blue"
                  @click="downloadExport(exportLink.url)"
                  download>
            Exporter
          </button>
        </div>
      </div>
      
      <div class="tab_holder">
        <table class="table tablestyle_3 table-striped">
          <thead>
            <tr>
              <th class="th-blue"
                  scope="col firstth">
                Catégorie du programme
              </th>
              <th class="th-blue text-left"
                  scope="col firstth">
                Nom programme
              </th>
              <!-- <th class="text-left th-blue">
                Année d'exercice
              </th> -->
              <th class="text-left th-blue">
                Date de début
              </th>
              <th class="text-left th-blue">
                Date de fin
              </th>
              <th class="text-left th-blue">
                Date de lancement
              </th>
              <th class="text-left th-blue">
                Date de validation
              </th>
              <th class="text-left th-blue">
                Date de clôture
              </th>
              <th class="text-left th-blue">
                Date de transmission du rapport de clôture
              </th>
              <th class="text-right th-blue">
                Montant initial
              </th>
              <th class="text-right th-blue">
                Montant révisé
              </th>
              <th class="text-right th-blue">
                Montant définitif
              </th>
            </tr>
          </thead>
          <tbody v-if="!loaded">
            <tr>
              <td colspan="12"
                  class="text-center">Chargement...</td>
            </tr>
          </tbody>
          <tbody v-else-if="long != 0">
            <tr v-for="(programme, key) in programmesV2.donnees"
                :key="key"
                class="text-uppercase"
                @click="launchUpdate(programme.id)">
              <td class="text-uppercase">
                {{ programme.categorie_programme_libelle }}
              </td>
              <td class="text-uppercase text-left">
                {{ programme.libelle }}
              </td>
              <!-- <td class="text-left"
                  style="width: 5%;">{{ programme.annee_exercice }}</td> -->
              <td class="text-left"
                  style="width: 7%;">{{ programme.date_debut }}</td>
              <td class="text-left"
                  style="width: 7%;">{{ programme.date_fin }}</td>
              <td class="text-left">{{ programme.date_lancement_programme }}</td>
              <td class="text-left">{{ programme.date_validation_programme }}</td>
              <td class="text-left">{{ programme.date_cloture_programme }}</td>
              <td class="text-left"
                  style="width: 8.8%;">{{ programme.date_transmission_rapport_cloture_programme }}</td>
              <td class="text-right"
                  style="width: 10.5%;">{{ Math.round(programme.montant_initial_programme).toLocaleString() }} FCFA</td>
              <td class="text-right"
                  style="width: 10%;">{{ Math.round(programme.montant_revise).toLocaleString() }} FCFA</td>
              <td class="text-right"
                  style="width: 10.5%;">{{ Math.round(Number(programme.montant_initial_programme) + Number(programme.montant_revise)).toLocaleString() }} FCFA</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12"
                  class="text-center">Aucun programme enregistré!</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row pagination">
        <div v-if="programmesV2.pagination"
             class="col-md-2 mx-auto text-center">
          <span v-if="programmesV2.pagination.precedent"
                class="badge badge-pill badge-info"
                @click="pIndex--, paging(programmesV2.pagination.precedent)"> &#60; </span>
          {{ pIndex }}
          <span v-if="programmesV2.pagination.suivant"
                class="badge badge-pill badge-info"
                @click="pIndex++, paging(programmesV2.pagination.suivant)"> &#62; </span>
        </div>
      </div>
    </div>
    <!-- Modal donnée historique-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Importer données historiques
                </div>
              </div>
            </div>
            <!-- <h5 class="modal-title"
                id="exampleModalLabel">Importer données historiques</h5> -->
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="col-md-11 form-group">
                  <label for=""
                  >Programme</label
                  >
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.donnees_histo }}
                    </label>

                    <input type="file"
                           name="etat_comptable"
                           class="custom-file-input"
                           id="donnees_histo"
                           ref="donnees_histo"
                           v-on:change="handleFileUpload('donnees_histo')"
                           accept=".xlsx,.csv" />
                    <span v-if="form.errors().has('donnees_histo')"
                          v-text="form.errors().get('donnees_histo')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading"/>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="templateDownload('programmesFinal.xlsx')">Télécharger un template</button>
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    :disabled="url == ''"
                    @click="submit">Importer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./programme.css";

</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import utils from "@/assets/js/utils"
import Notif from "@/components/shared/Toast"
import Loader from "@/components/shared/Spinner"
import ListFilter from "@/components/shared/Filter"

// import HTTP from "@/Api/auth"

export default {
  name: "Programme",
  components:{
    Loader,
    Notif,
    ListFilter,
  },
  data: () => ({
    programmeData: {
      libelle: "",
      order: "",
      tri: ""
    },
    filtre:[],
    chmp:[
      {
        libelle:"Nom programme",
        valeur:"nom_programme",
        type:"caractere",
        table:"programmes",
      },
      {
        libelle:"Date de début",
        valeur:"date_debut",
        type:"date",
        table:"programmes",
      },
      {
        libelle:"Date de fin",
        valeur:"date_fin",
        type:"date",
        table:"programmes",
      },
      {
        libelle:"Date de lancement",
        valeur:"date_lancement_programme",
        type:"date",
        table:"programmes",
      },
      {
        libelle:"Date de validation",
        valeur:"date_validation_programme",
        type:"date",
        table:"programmes",
      },
      {
        libelle:"Date de clôture",
        valeur:"date_cloture_programme",
        type:"date",
        table:"programmes",
      },
      {
        libelle:"Date de transmision du rapport de clôture",
        valeur:"date_transmission_rapport_cloture_programme",
        type:"date",
        table:"programmes",
      },
      {
        libelle:"Montant initial",
        valeur:"montant_initial_programme",
        type:"numeric",
        table:"programmes",
      },
      {
        libelle:"Montant révisé",
        valeur:"montant_revise",
        type:"numeric",
        table:"programmes",
      },
      {
        libelle:"Année d'exercice",
        valeur:"annee_exercice",
        type:"numeric",
        table:"programmes",
      },
    ],
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    exportation:{
      titre:"Liste des programmes",
      contenu:[],
      entete:[],
      nom_utilisateur:"",
      departement:"",
      date_impression:"",
    },
    uploadfile :"",
    listExtensionFiles: [],
    user: "",
    baseUrl: process.env.VUE_APP_UPLOAD,
    long: null,
    url:"",
    loading:false,
    pIndex: 1,
    inputFiles: {  
      donnees_histo:"" 
    },
    form: form({
      donnees_histo:"" 
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
    loaded: false
  }),
  watch: {
    programmesV2() {
      if (this.programmesV2.donnees) {
        this.programs = this.programmesV2.donnees
        this.loaded = true
        this.long = this.programmesV2.donnees.length
        this.$refs["btn-close"].click()
        
        // remplir la case contenu pour l'exportation
        this.exportation.contenu = this.programs
        
        // remplir la case entete pour l'exportation
        var listBruteKey= Object.keys(this.programs[0])//recuperation des champs bruts
        this.exportation.entete=[]//initialisation du champs entete de l'exportation
        for (let index = 0; index < listBruteKey.length; index++){//boucle pour parcourir la liste des champs bruts
          this.exportation.entete.push("")//creation de la cellule accueillant le nom de champs traité
          if (listBruteKey[index].includes("_")) {//check si la chaine contient un underscore
            var modified_key= listBruteKey[index].split("_")//split la chaine par underscore
            for (let i = 0; i < modified_key.length; i++) {//boucle pour parcourir la chaine splitée
              if (i+1==modified_key.length) {//condition pour ne pas ajouter un espace à la fin de la chaine
                this.exportation.entete[index]+=modified_key[i]
              }else{
                this.exportation.entete[index]+=modified_key[i]+" "
              }
            }
          }else{//au cas où il n'y a pas de underscore
            this.exportation.entete[index]=listBruteKey[index]
          }
        }
        //déclenchement de l'api call (exporterDonnees)
        this.exporterDonnees(this.exportation)
        console.log(this.exportation)
      }
    },
    singleProgrammesV2(){
      if(this.singleProgrammesV2){
        this.notif.message = this.singleProgrammesV2
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setProgrammesV2("")
            this.singleProgramesV2("")
            this.Programmes(null)
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    errors(){
      if (this.errors) {
        this.notif.message = this.errors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.setFiles("")
            this.form[this.uploadfile]=""
            this.inputFiles.donnees_histo=""
            this.$refs["donnees_histo"].value=""
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
        this.loading=false
      }
    }
  },
  created() {
    // console.log(this)
    localStorage.setItem("module","programmes")
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.ProgrammesV2(null)
    this.listExtensionFiles = utils.formaFichierAccepte
    
    // remplir la case date_impression pour l'exportation
    var jour = new Date()
    this.exportation.date_impression=""
    if (jour.getDate() < 10) {
      this.exportation.date_impression ="0" 
    }
    this.exportation.date_impression+=jour.getDate()+"-"
    if (jour.getMonth() < 9) {
      this.exportation.date_impression +="0"
    }
    this.exportation.date_impression+=(jour.getMonth()+1)+"-"
    this.exportation.date_impression += jour.getFullYear()
    
    // remplir la case nom_utilisateur pour l'exportation
    this.exportation.nom_utilisateur = userdetails.nom
    
    // remplir la case departement pour l'exportation
    switch (this.user[0]) {
      case "DT":
        this.exportation.departement = "Direction Technique"
        break
      case "DCG":
        this.exportation.departement = "Direction du Contrôle de Gestion"
        break
      case "DAF":
        this.exportation.departement = "Direction Administrative et Financière/ Ingenierie Financière"
        break
      case "ADMIN":
        this.exportation.departement = "Administrateur"
        break
      default:        
        this.exportation.departement = "Autre"
        break
    }
  },
  computed: {
    ...mapGetters(["programmesV2","singleProgrammesV2", "errors","files","exportLink","failExportLink"])
  },
  methods: {
    ...mapActions(["importProgrammesV2","ProgrammesV2","saveFile", "pageProgrammesV2","exporterDonnees"]),
    ...mapMutations(["setProgrammesV2","singleProgramesV2","setFiles","setErors","setExportLink","setFailExportLink"]),
    launchUpdate(id) {
      this.$router.push({ name: "DetailProgramme", params: { id: id } })
    },
    filtrer() {
      this.loaded = false
      this.long = null
      this.setProgrammesV2("")
      this.ProgrammesV2(this.filtre)
    },
    restoring() {
      this.loaded = false
      this.long = null
      this.setProgrammesV2("")
      this.ProgrammesV2(null)
      this.programmeData = { libelle: "", order: "", tri: "" }
      this.filtre = { libelle: "", specification: "", valeur: "" }
    },
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      // this.uploadfile = filename
      const extension = this.inputFiles[filename].split(".")[1]

      if (this.listExtensionFiles.includes(extension)) {
        // this.loading[filename] = true
        // this.disabled = true
        this.loading= true
        this.uploadfile = filename
        this.saveFile(this.$refs[filename].files[0])
      } else {
        this.inputFiles[filename] = ""
        this.notif.message = "Mauvais format du fichier !!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
      // this.selectedFile = this.$refs[filename].files[0]
      // this.saveFile(this.$refs[filename].files[0])
    },
    submit() {
      this.importProgrammesV2({url:this.url})
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      
      this.pageProgrammesV2(url)
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
  
}
</script>
