import HTTP from './../../Api/auth'
const state = {
  listCategories: [],
  categorie:'',
  failListCategorie:'',
  msgSuccessCategorie:'',
  msgFailCategorie:'',
}

const getters = {
  listCategories: state => state.listCategories,
  failListCategories: state => state.listCategories,
  categorie: state => state.categorie,
  msgSuccessCategorie:state => state.msgSuccessCategorie,
  msgFailCategorie:state => state.msgFailCategorie,
}

const actions = {
    getListCategories({commit}){
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"categories-programmes").then((response) => {
      commit("setListCategories", response.data)
    }).catch((errors) => {
      commit("setFailListCategorie", errors.response.data.code_message)
    })
  },
  postCategorie({commit},data){
    HTTP.post(process.env.VUE_APP_AUTHURL_V2+"categories-programmes", data).then((response) => {
      commit("setMsgSuccessCategorie", {msg:'Catégorie a été créée avec succès.',data:response.data})
      commit("setMsgFailCategorie", '')
    }).catch((errors) => {
      commit("setMsgSuccessCategorie",'')
      commit("setMsgFailCategorie", errors.response.data.code_message)
    })
  },
  getCategorie({commit},data){
    console.log("data")
    console.log(data)
    HTTP.get(process.env.VUE_APP_AUTHURL_V2+"categories-programmes/"+data.id, data).then((response) => {
      commit("setCategorie", response.data)
    }).catch((errors) => {
      commit("setMsgFailCategorie", errors.response.data.code_message)
    })
  },
  updateCategorie({commit},data){
    HTTP.put(process.env.VUE_APP_AUTHURL_V2+"categories-programmes/"+data.id,data).then((response) => {
      commit("setMsgSuccessCategorie", {msg:'Catégorie a été modifiée avec succès.',data:response.data})
      commit("setMsgFailCategorie", '')
    }).catch((errors) => {
      commit("setMsgSuccessCategorie",'')
      commit("setMsgFailCategorie", errors.response.data.code_message)
    })
  },
}
const mutations = {
  setListCategories: (state, categories) => (
    state.listCategories = categories
  ),
  setFailListCategories: (state, categories) => (
    state.failListCategorie = categories
  ),
  setCategorie: (state, categorie) => (
    state.categorie = categorie
  ),
  setMsgSuccessCategorie: (state, categorie) => (
    state.msgSuccessCategorie = categorie
  ),
  setMsgFailCategorie: (state, error) => (
    state.msgFailCategorie = error
  ),
}

export default {
    state,
    getters,
    actions,
    mutations
}
